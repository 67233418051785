* {
  margin: 0;
  padding: 0;
  line-height: normal;
}
html,
body,
#root {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overscroll-behavior: none;
}

.app {
  min-height: 100%;
  height: 100%;
}

.colorWeak {
  filter: invert(80%);
}

.ant-layout {
  min-height: 100vh;
}

.ant-pro-sider.ant-layout-sider.ant-pro-sider-fixed {
  left: unset;
}

.ant-tabs-tab {
  font-weight: 600;
}

canvas {
  display: block;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul,
ol {
  list-style: none;
}

@font-face {
  font-family: Elza;
  src: url("../Assets/fonts/Elza-Regular.woff");
}

@font-face {
  font-family: Elza-Medium;
  src: url("../Assets/fonts/Elza-Medium.woff");
}

@font-face {
  font-family: Elza-Semibold;
  src: url("../Assets/fonts/Elza-Semibold.woff");
}

@media (max-width: 768px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
    &-thead > tr,
    &-tbody > tr {
      > th,
      > td {
        white-space: pre;
        > span {
          display: block;
        }
      }
    }
  }
}

.ant-table-wrapper .ant-table-thead > tr > th {
  font-family: Elza-Semibold;
}

.ant-form-item .ant-form-item-label > label {
  font-weight: 600;
}

